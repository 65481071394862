export const prod = [
    {
        title: 'Object-Oriented Programming Review',
        code: 'CP-ARCH-1001',
        id: '90503ea1-5004-4feb-a54e-b836b8f08693'
    },
    {
        title: 'Test-Driven Development and Behavior Driven Development',
        code: 'CP-ARCH-1002',
        id: 'd1213122-d285-425f-9e68-2207b69bc646'
    },
    {
        title: 'Introduction to Solutions Architecture',
        code: 'CP-ARCH-1003',
        id: 'eb6d2db7-b22a-4b1d-a43c-2fe7350c3a23'
    },
    {
        title: 'Information Security: OWASP Review',
        code: 'CP-ARCH-1004',
        id: '8bbe1b7f-17e5-4b4b-ab52-7f89ac7990b8'
    },
    {
        title: 'Networking: IP Networks Review',
        code: 'CP-ARCH-1005',
        id: '89b7f5b5-db63-43d7-ba4d-0b75925e03d0'
    },
    {
        title: 'Cloud Architecture: Developing for the Cloud',
        code: 'CP-ARCH-1006',
        id: '71bd1265-0e43-4e29-b365-639b7691627e'
    },
    {
        title: 'Cloud Architecture: Key Pillars for Cloud Applications',
        code: 'CP-ARCH-1007',
        id: '1cc2a3b1-342f-477b-875c-ed4f9b7ea079'
    },
    {
        title: 'Cloud Native Program: Containers',
        code: 'CP-ARCH-1008',
        id: '5e9c932d-4927-4d98-9f9e-6982ad478884'
    },
    {
        title: 'Cloud Native Program: Kubernetes',
        code: 'CP-ARCH-1009',
        id: '469e61bd-9ee4-4fcb-aef6-905f8f338398'
    },
    {
        title: 'DevSecOps: Infrastructure-as-Code',
        code: 'CP-ARCH-1010',
        id: '7c1b6f5b-d975-41c7-850a-6b18099da984'
    },
    {
        title: 'DevSecOps: Pipeline Engineering and CI/CD',
        code: 'CP-ARCH-1011',
        id: '5849bda6-c875-4944-8f51-9098624d2447'
    },
    {
        title: 'Cloud Development: Developing on AWS Cloud',
        code: 'CP-ARCH-1012',
        id: '7dc9541c-1a1d-44c6-a176-96f06e03f526'
    },
    {
        title: 'Platform Engineering: APIs, Service Meshes',
        code: 'CP-ARCH-1013',
        id: 'b1a7207d-05fb-4d3c-a207-608776b3c939'
    },
    {
        title: 'Platform Engineering: Authentication and Authorization',
        code: 'CP-ARCH-1014',
        id: '3931b318-b83d-4cd8-9bc3-eb03c48ecf38'
    },
    {
        title: 'Platform Architecture: Event Driven Architectures ',
        code: 'CP-ARCH-1015',
        id: 'e80ebbb7-b4bd-4a9c-a569-802d868a296d'
    },
    {
        title: 'Platform Architecture: Microservices Patterns',
        code: 'CP-ARCH-1016',
        id: '418726de-67ff-4b82-a153-1e5b312b6605'
    },
    {
        title: 'Platform Architecture: Cache-based Architectures',
        code: 'CP-ARCH-1017',
        id: 'dffbb814-cae8-4bd8-b718-3318551063d8'
    }
];
